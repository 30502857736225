import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import styles from "../jss/landing-webdesign.js"
import classNames from "classnames"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"

import Grid from "@material-ui/core/Grid"
import HeaderLinks from "../components/Header/HeaderLinks.jsx"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Intro from "../pages-sections/Web-Development/Intro.jsx"
import Pricing from "../pages-sections/Web-Development/Pricing.jsx"
import Contact from "../pages-sections/Web-Development/Contact.jsx"
import Marketing from "../pages-sections/Web-Development/Marketing.jsx"
import Banner from "../pages-sections/Web-Development/Banner.jsx"
import FAQ from "../pages-sections/Web-Development/FAQ.jsx"
import Info from "../pages-sections/Web-Development/Info.jsx"
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

import {
  title,
  container,
  whiteColor,
  main,
  mainRaised,
} from "../jss/nextjs-material-kit-pro.js"
// Sections for this page
const useStyles = makeStyles({
  container: {
    ...container,
    zIndex: "10",
    color: "#FFFFFF",
    position: "relative",
  },

  mainRaised: {
    ...main,
    ...mainRaised,
  },
  parallax: {
    height: "70vh",
    "@media screen and (max-width:500px)": {
      height: "60vh",
    },
  },
  title: {
    ...title,
    color: whiteColor + "  !important",
    marginBottom: "25px",
    minHeight: "32px",
    marginLeft: "100px",
    "@media screen and (max-width:500px)": {
      padding: "10px 0px 0px 0px",
      fontSize: "28px",
      marginLeft: "-10px",
      marginTop: "80px",
    },
  },
  container2: {
    "@media screen and (max-width:500px)": {
      padding: "0px 0px 0px 0px",
      fontSize: "28px",
    },

    "@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)": {
      marginTop: "240px",
      textAlign: "center",
    },

    "@media screen and (min-width:1100px)": {
      marginTop: "160px",
    },
  },
  container3: {
    fontSize: "18px",
    "@media screen and (min-width:1500px)": {
      marginLeft: "100px",
    },
    "@media screen and (max-width:500px)": {
      padding: "-250px 0px 0px 0px",
    },
  },
  blackOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    height: "100%",
    paddingTop: "20px",
    color: "white",
  },
})

const getImages = graphql`
  {
    file(relativePath: { eq: "web-design.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default function LandingPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  const data = useStaticQuery(getImages)
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title="Toronto Web Design| Kelowna Web Design | Atlas Agency "
        description="Work with an experienced Kelowna and Toronto  Web Design Company. We offer web design services in Toronto and Kelowna."
        canonical="https://atlasagency.ca/toronto-web-design/"
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        brand="AMP Agency"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 60,
          color: "dark",
        }}
        {...rest}
      />

      <div filter="dark" className={classes.parallax}>
        <div className={classes.blackOverlay}>
          <Grid container justify="center">
            <Grid
              item
              xs={10}
              sm={10}
              md={8}
              lg={10}
              className={classes.container2}
            >
              <h1 className={classes.title}>Launch With Confidence</h1>
            </Grid>
            <Grid item xs={10} sm={10} md={8} lg={10}>
              <h2 className={classes.container3}>
                All our websites are optimized for mobile, tablet and desktop
                use. Content is optimized for each platform giving your users an
                amazing experience.
              </h2>
            </Grid>

            <Img
              fluid={data.file.childImageSharp.fluid}
              fadeIn={false}
              alt="Toronto-Web-Design"
              loading="eager"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: "-1",
              }}
            />
          </Grid>
        </div>
      </div>

      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ overflowX: "hidden" }}
      >
        <div className={classes.container}>
          <Intro />
        
        </div>
        <div>
          <Marketing />
        </div>

        <div>
          <Pricing />
        </div>
        <div style={{ padding: "20px 0px 20px 0px" }}>
          <Banner />
          <div className={classes.container}>
            <Info />
          </div>
          <Banner />
        </div>
        <div className={classes.container}>
          <FAQ />
        </div>
        <Banner />
        <div className={classes.container}>
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
