import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"

import styles from "../../jss/infoWeb.js"

import { Link } from "gatsby"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
  link: {
    fontSize: "20px",
    color: "#273746",
  },
  containerInfo: {
    paddingTop: "40px",
    paddingBottom: "40px",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" className={classes.containerInfo}>
        <Grid item xs={12} sm={12} md={8} lg={12}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            {" "}
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            Quality web design is a necessity for improving your online
            presence. We help our customers decide on the right look for their
            website while providing innovative and effective solutions. Call us
            today and use your website to make the right statement.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={12}>
          <h3
            className={classes.title}
            style={{ color: "#273746", fontSize: "25px" }}
          >
            <strong>YOU MIGHT ALSO BE INTERESTED IN...</strong>
          </h3>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          style={{ paddingBottom: "20px", position: "relative", top: "10px" }}
        >
          <ArrowRightAltIcon
            style={{ color: "#212121", position: "relative", top: "5px" }}
          />
          <Link
            to="/website-design-for-electrical-contractors"
            href="https://atlasagency.ca/website-design-for-electrical-contractors"
            className={classes.link}
          >
            Website Design for Electrical Contractors
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          style={{ paddingBottom: "20px", position: "relative", top: "10px" }}
        >
          <ArrowRightAltIcon
            style={{ color: "#273746", position: "relative", top: "5px" }}
          />
          <Link
            to="/contractor-website-design"
            href="https://atlasagency.ca/contractor-website-design"
            className={classes.link}
          >
            Contractor Website Design
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          style={{ paddingBottom: "20px", position: "relative", top: "10px" }}
        >
          <ArrowRightAltIcon
            style={{ color: "#273746", position: "relative", top: "5px" }}
          />
          <Link
            to="/painting-contractor-website-design"
            href="https://atlasagency.ca/painting-contractor-website-design"
            className={classes.link}
          >
            Painting Contractor Website Design
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          style={{ paddingBottom: "20px", position: "relative", top: "10px" }}
        >
          <ArrowRightAltIcon
            style={{ color: "#273746", position: "relative", top: "5px" }}
          />
          <Link
            to="/financial-advisor-web-design"
            href="https://atlasagency.ca/financial-advisor-web-design"
            className={classes.link}
          >
            Financial Advisor Web Design
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          style={{ paddingBottom: "20px", position: "relative", top: "10px" }}
        >
          <ArrowRightAltIcon
            style={{ color: "#273746", position: "relative", top: "5px" }}
          />
          <Link
            to="/doctors-web-design"
            href="https://atlasagency.ca/doctors-web-design"
            className={classes.link}
          >
            Doctors Web Design
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          style={{ paddingBottom: "20px", position: "relative", top: "10px" }}
        >
          <ArrowRightAltIcon
            style={{ color: "#273746", position: "relative", top: "5px" }}
          />
          <Link
            to="/accountant-web-design"
            href="https://atlasagency.ca/accountant-web-design"
            className={classes.link}
          >
            Accountant Web Design
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          style={{ paddingBottom: "20px", position: "relative", top: "10px" }}
        >
          <ArrowRightAltIcon
            style={{ color: "#273746", position: "relative", top: "5px" }}
          />
          <Link
            to="/lawyer-web-design"
            href="https://atlasagency.ca/lawyer-web-design"
            className={classes.link}
          >
            Lawyer Web Design
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}
