import React from "react"
import { navigate } from "gatsby"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
// @material-ui/icons
import addToMailchimp from "gatsby-plugin-mailchimp"
// core components

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import styles from "../../jss/contactStyle.js"
import { openPopupWidget } from "react-calendly"

const useStyles = makeStyles(styles)

export class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = { email: "", result: null, MESSAGE: "" }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email, {
      MESSAGE: this.state.MESSAGE,
    })
      .then(data => {
        this.setState({ result: data })
        navigate("/thank-you")
      })
      .catch(() => {})
  }
  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  handleMessage = event => {
    this.setState({ MESSAGE: event.target.value })
  }
  render() {
    const classes = this.props.classes

    const CustomButton = ({ url, prefill, pageSettings, utm }) => {
      const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm })

      return (
        <Button
          style={{
            backgroundColor: "#213f94",
            color: "white",
            marginTop: "10px",
            height: "60px",
            fontSize: "20px"
          }}
          variant="contained"
          onClick={onClick}
        >
          Free Consultation
        </Button>
      )
    }

    return (
      <div className={classes.section} style={{ marginTop: "-20px" }}>
        <Grid container justify="center">
          <Grid item cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Get Started</h2>
            <p className={classes.description}>
              The SEO consultants and experts from Atlas Agency ensure you
              receive consistent traffic and organic leads to your web page.
              Contact us and find out more about our services.
            </p>
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              style={{ textAlign: "center" }}
            >
              <p className={classes.description}>
                Book a free 15 minute consultation to learn more about how to
                improve your online presence.
              </p>
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                style={{ textAlign: "center" }}
              >
                <CustomButton url="https://calendly.com/atlasagency/15min" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default () => {
  const classes = useStyles()
  return <MailChimpForm classes={classes} />
}
