import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
// core components

import styles from "../../jss/intro.js"

import Grid from "@material-ui/core/Grid"
// import { FAQPageJsonLd } from "next-seo"
import Accordion from "../../components/Accordion/Accordion.jsx"
import { FAQJsonLd } from "gatsby-plugin-next-seo"
const useStyles = makeStyles({
  ...styles,

  paragraph: {
    color: "#212121",
    fontSize: "18px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  mobileTitleFAQ: {
    fontSize: "30px",
    lineHeight: "35px",
    color: "#d0a057",
    fontWeight: "bold",
    textAlign: "center",
  },
})

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div>
      <FAQJsonLd
        questions={[
          {
            question: "How much do you charge for a website?",
            answer:
              "We quote your website based on the number of pages you’d like to have. Once we determine your scope of work, we will provide a detailed cost break down. Schedule a call with us to go over the details and we can turn around a quote within a single business day. ",
          },
          {
            question: "How long does it take to design?",
            answer:
              "A complete website will take usually 3-6 weeks depending on the number of pages. If content and pictures are provided it can typically take 2-3 weeks. Majority of the time in the beginning is towards organizing and understanding your needs. Reach out to us today to get an estimate and timeline for your next website. ",
          },
          {
            question: "Do you provide website hosting?",
            answer:
              "We provide free web hosting on our platforms. Start saving over $800 a year by launching a website through us!",
          },
          {
            question: "How do we get started?",
            answer:
              "Contact us at info@atlasagency.ca or (647)-371-0263 with your website details. Let us know what your business does and how you’d like to share your services online. We will provide you a quote and timeline for your new website.",
          },
          {
            question: "How do web developers design a website?",
            answer:
              "Every developer has their preference, and we use the most advanced tools developed by Facebook and Google to build our websites. We use tools approved by Google in order to give you the best results for search rankings.",
          },
        ]}
      />
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        direction="column"
        className={classes.desktopContainerInfo}
      >
        <Grid item lg={12}>
          <h2
            className={classes.title}
            style={{ color: "#273746", textTransform: "uppercase" }}
          >
            {" "}
            Frequently Asked Questions (FAQ)
          </h2>
        </Grid>
        <Grid item xs={12} sm={8} md={8} style={{ paddingBottom: "40px" }}>
          <Accordion
            active={0}
            activeColor="info"
            collapses={[
              {
                title: "How much do you charge for a website?",
                content:
                  "We quote your website based on the number of pages you’d like to have. Once we determine your scope of work, we will provide a detailed cost break down. Schedule a call with us to go over the details and we can turn around a quote within a single business day. ",
              },
              {
                title: "How long does it take to design?",
                content:
                  "A complete website will take usually 3-6 weeks depending on the number of pages. If content and pictures are provided it can typically take 2-3 weeks. Majority of the time in the beginning is towards organizing and understanding your needs. Reach out to us today to get an estimate and timeline for your next website. ",
              },
              {
                title: "Do you provide website hosting?",
                content:
                  "We provide free web hosting on our platforms. Start saving over $800 a year by launching a website through us!",
              },
              {
                title: "How do we get started?",
                content:
                  "Contact us at info@atlasagency.ca or (647)-371-0263 with your website details. Let us know what your business does and how you’d like to share your services online. We will provide you a quote and timeline for your new website.",
              },
              {
                title: "How do web developers design a website?",
                content:
                  "Every developer has their preference, and we use the most advanced tools developed by Facebook and Google to build our websites. We use tools approved by Google in order to give you the best results for search rankings.",
              },
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}
