import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import CloudDoneIcon from "@material-ui/icons/CloudDone"
import DevicesIcon from "@material-ui/icons/Devices"
import CreateIcon from "@material-ui/icons/Create"

// core components
import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"
import Contact from "./Contact.jsx"
import styles from "../../jss/intro.js"

const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section} style={{ paddingBottom: "80px" }}>
      <Grid container justify="center">
        <Grid item xs={12} sm={12} md={8} lg={12}>
          <h1 className={classes.title}>Toronto Web Design</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={12}
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            An experienced team of web designers in Toronto. Atlas Agency can
            help your business get the online traffic it deserves. Stand out
            from the competition with a future proof website built by a
            professional team.
          </p>
          <p className={classes.description}>
            Consumers are more likely to purchase a service from a
            mobile-friendly website. As a Toronto web design agency we have extensive experience in modern design solutions including,
            optimizing websites for mobile, tablet, and desktop views. The sites
            we launch automatically present the information depending on the
            device’s screen size. Besides mobile optimization, our web design
            services include:
          </p>
        </Grid>
      </Grid>

        <Grid container spacing={4} justify="center" >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Custom Made Websites"
              description="We provide resources that will make your website stand out from competitors. We don't use website templates and create everything from scratch. Our custom website approach provides companies with professional websites."
              icon={CreateIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Hosting Services"
              description="The web development team at Atlas Agency ensures that your website is available to visitors 24/7. Our websites make customer service easier with user-friendly design and contact buttons. We also help with sourcing your desired domain and connecting it to your web page."
              icon={CloudDoneIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile & Desktop Optimized"
              description="As an increasing amount of web traffic comes from mobile devices, it is essential to have a site that is responsive to different screen sizes and shapes."
              icon={DevicesIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
        <Grid item lg={12} xs={10} sm={6} md={5} style={{paddingTop: "20px"}}>
 
              <iframe
                className={classes.image}
                src="https://www.youtube.com/embed/a3SQc5ILmG8?enablejsapi=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"
              />
  
          </Grid>
        <Grid item xs={12} sm={12} md={4} lg={12} style={{ position: "relative" ,top: "-40px" }}>
            <Contact />
          </Grid>

    </div>
  )
}
