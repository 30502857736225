import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"
// import mobileWeb from "../../public/mobile-web-developers-toronto.svg";

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "toronto-web-design-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div
      className={classNames(classes.section, classes.sectionDark)}
      style={{ marginBottom: "80px" }}
    >
      <Grid container justify="center" alignItems="center" spacing={5}>
        <Grid item lg={4} xs={10}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Toronto-Web-Designer"
            fadeIn={false}
            loading="eager"

          />
        </Grid>
        <Grid item md={5} xs={10}>
          <div>
            <h3 className={classes.title}>SEO-Friendly Websites</h3>
            <h6 className={classes.description}>Toronto Web Design</h6>
            <h5 className={classes.description}>
              For most companies in Toronto, web design is the main tool for
              attracting more visitors. However, besides sparking interest in
              consumers, it can also play a large role in your SEO and Google
              rank. If you are looking for a web designer in Toronto, work with
              us to launch your next website.
            </h5>
            <h5 className={classes.description}>
              Toronto web design company providing SEO-friendly web design
              solutions. We develop and design websites so that search engines
              can understand their content and rank them higher in relevant
              results. For us, every detail matters. From title and picture tags
              to keyword placement and meta titles, we carefully craft your
              website and confidently launch it to Google’s search engine
              results.
            </h5>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
