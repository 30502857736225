import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons

// core components

import Card from "../../components/Card/Card.js"
import CardBody from "../../components/Card/CardBody.js"

import Grid from "@material-ui/core/Grid"

import styles from "../../jss/pricingStyle.js"

const useStyles = makeStyles(styles)

export default function ProductSection({ ...rest }) {
  const classes = useStyles()
  return (
    <div className="cd-section" {...rest}>
      <Grid container justify="center" spacing={4}>
        <Grid item md={12} xs={10}>
          <h2 className={classes.title} style={{ textAlign: "center" }}>
            LAUNCH WITH CONFIDENCE
          </h2>
        </Grid>

        <Grid item md={6} xs={10}>
          <h5 className={classes.description2}>
            Every web designer in Toronto must be well-informed and stay ahead
            of competitors. At our company, we use superior web design platforms
            and technologies. Working with us means creating your website
            according to the latest trends in web development and design. We are
            proud to offer web hosting through the following platform:
          </h5>
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2}>
        <Grid item sm={4} md={5} lg={3} xs={11}>
          <Card pricing color="success">
            <CardBody pricing>
              <h2 className={classes.cardDescription}>CUSTOM DESIGN</h2>
              <h6>OUR WEBSITE</h6>
              <ul>
                <li>Most advanced front-end platform</li>
                <li>Trusted by Tesla, Airbnb, VW, Nike</li>
                <li>Optimized for mobile, tablet & desktop</li>
                <li>
                  <b>Free</b> website hosting
                </li>
                <li>
                  <b>Ideal for Medium - Large Businesses</b>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Grid>

        <Grid item sm={4} md={5} lg={3} xs={11}>
          <Card pricing color="success">
            <CardBody pricing>
              <h2 className={classes.cardDescription}>SQUARESPACE DESIGN</h2>
              <ul>
                <li>Perfect for Startups & Small businesses</li>

                <li>Optimized for Mobile, Tablet & Desktop</li>
                <li>
                  <b>Monthly</b> Website Hosting
                </li>
                <li>
                  <b>Ideal for Small Businesses</b>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Grid>

        <Grid item sm={4} md={5} lg={3} xs={11}>
          <Card pricing color="success">
            <CardBody pricing>
              <h2 className={classes.cardDescription}>SHOPIFY DESIGN</h2>
              <ul>
                <li>Multiple E-commerce Themes</li>

                <li>Includes Sales Analytics & Online Payments</li>

                <li>Optimized for Mobile, Tablet & Desktop</li>
                <li>
                  <b>Monthly</b> Website Hosting
                </li>
                <li>
                  <b>Ideal for E-commerce Businesses</b>
                </li>
              </ul>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
